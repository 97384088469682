import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import SignIn from '../components/templates/user/sign-in';
export const titleMessage = defineMessages({
  title: {
    id: 'v8Culq',
    defaultMessage: 'Sign In to Continue',
    description: 'Title for sign in page.',
  },
});

const LoginPage = () => {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      <SignIn />
    </>
  );
};
export default LoginPage;
