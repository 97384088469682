import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';
import { SimpleTemplateContainer } from '..';
import LoginFormStandalone from '@components/organisms/login-form-standalone/login-form-standalone';

export const messages = defineMessages({
  treasurerProcessSignInTitle: {
    id: '/DmmfE',
    defaultMessage: 'Sign In to Continue Registration Process',
    description: 'Heading for custom sign in message',
  },
});

const SignIn = () => {
  const intl = useIntl();
  const router = useRouter();
  const isTreasurerRegistration = /\/treasurer.*\/registration/.test(
    `${router.query.callbackUrl}`
  );
  const customTitle = isTreasurerRegistration
    ? intl.formatMessage(messages.treasurerProcessSignInTitle)
    : undefined;
  const customStyles = isTreasurerRegistration
    ? 'min-[400px]:max-w-[350px] mx-auto'
    : undefined;
  return (
    <SimpleTemplateContainer isContainer>
      <LoginFormStandalone
        isOauth={false}
        title={customTitle}
        headingClasses={customStyles}
      />
    </SimpleTemplateContainer>
  );
};
export default SignIn;
