import { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import LoginSecondFactor from '../login-second-factor/login-second-factor';
import { ForgotPassword } from '../../organisms/forgot-password/forgot-password';
import BackLink from '../../molecules/back-link/back-link';
import InputText from '../../atoms/input-text/input-text';
import { Button } from '../../atoms/button/button';
import useLoginForm from '@hooks/use-login-form';
import { getElementError } from '@hooks/use-form-validation';
import Message from '@components/molecules/message/message';
import Checkbox from '@components/atoms/checkbox/checkbox';
import { IconUser } from '@components/atoms/icons';
import { Link } from '@components/atoms/link/link';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import { Heading } from '@components/atoms/heading/heading';
import { titleMessage } from 'pages/login';
import { getDonateFlowUrl } from '@components/templates/user/guest-registration';
interface Props {
  isOauth: boolean;
  title?: string;
  headingClasses?: string;
}

export const messages = defineMessages({
  labelEmail: {
    id: 'KC3rcq',
    defaultMessage: 'Email',
    description: 'Label for email input',
  },
  labelPassword: {
    id: 'RBfT+7',
    defaultMessage: 'Password',
    description: 'Label for password input',
  },
  labelStaySigned: {
    id: 'XovPE4',
    defaultMessage: 'Stay signed in',
    description: 'Label for Stayed Signed in',
  },
  showPassword: {
    id: 'kMRvN6',
    defaultMessage: 'Show password',
    description: 'Label for show password option',
  },
});

const LoginFormStandalone = ({
  isOauth,
  title,
  headingClasses = '',
}: Props) => {
  const router = useRouter();
  const intl = useIntl();
  const isTreasurer = /\/treasurer/.test(`${router.query.callbackUrl}`);
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
    requiresSecondFactor,
    preferredSecondFactor,
  } = useLoginForm({ isOauth });
  const [forgotPassword, setForgotPassword] = useState(false);

  const getError = getElementError(errors, hasSubmitted);
  const { orgId } = router.query;

  return (
    <>
      <div className="flex">
        <div className="w-full text-center mb-4 relative">
          {!isOauth && (
            <div className="flex justify-right md:absolute left-0 top-2">
              <BackLink href={orgId ? `/donate/${orgId}` : undefined} />
            </div>
          )}
          <Heading
            variant="h2"
            as="h1"
            color="nad-blue"
            className={`my-4 md:mt-0 ${headingClasses}`}
            data-testid="sigin-title"
          >
            {title || intl.formatMessage(titleMessage.title)}
          </Heading>
        </div>
      </div>
      <div className="container-xs mb-32">
        {requiresSecondFactor && preferredSecondFactor && values.username && (
          <>
            <LoginSecondFactor
              className="mt-8 mb-12"
              isOauth={false}
              initialSecondFactor={preferredSecondFactor}
              username={values.username}
              isStandalone={true}
            />
          </>
        )}
        {!requiresSecondFactor && (
          <form method="post" onSubmit={handleSubmit} noValidate>
            {/* TODO: add registration for oauth */}
            {!isOauth && (
              <Message
                type="info"
                className="flex items-center justify-center gap-1"
              >
                <IconUser />
                <FormattedMessage
                  id="eQ97eL"
                  defaultMessage="Don't have an account? <a>Create one.</a>"
                  description="CTA to create an account on the login page."
                  values={{
                    a: (text) => (
                      <Link
                        href={getDonateFlowUrl('/register', orgId)}
                        className="underline"
                      >
                        {text}
                      </Link>
                    ),
                  }}
                />
              </Message>
            )}
            <div className="w-full flex flex-col mt-6 space-y-6">
              <InputText
                name="username"
                label={intl.formatMessage(messages.labelEmail)}
                value={values.username}
                onChange={handleChange}
                error={getError('username')}
                labelClasses="pb-1 inline-block"
                data-testid="username"
                autoComplete="username"
              />
              <InputText
                name="password"
                value={values.password}
                onChange={handleChange}
                label={intl.formatMessage(messages.labelPassword)}
                type={values.showPassword ? 'text' : 'password'}
                autoComplete={values.showPassword ? 'off' : 'current-password'}
                error={getError('password')}
                data-testid="password"
                labelClasses="pb-1 inline-block"
                helpTextTop={
                  // TODO: add forgot password for oauth
                  !isOauth && (
                    <button
                      type="button"
                      className="text-s underline text-nad-alps-night font-sans float-right cursor-pointer"
                      onClick={() => {
                        setForgotPassword(true);
                      }}
                    >
                      <FormattedMessage
                        id="BfjXiq"
                        defaultMessage="Forgot Password?"
                        description="CTA to open forgot password modal."
                      />
                    </button>
                  )
                }
              />
              <div className="flex flex-row justify-between">
                <Checkbox
                  name="stay_signed_in"
                  label={intl.formatMessage(messages.labelStaySigned)}
                  checked={values.stay_signed_in}
                  onChange={handleChange}
                />
                <Checkbox
                  name="showPassword"
                  label={intl.formatMessage(messages.showPassword)}
                  checked={values.showPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full flex flex-col space-y-6 !mt-8">
                <FormErrorMessage errors={submitError} />
                <div>
                  <Button
                    isLoading={isSubmitting}
                    disabled={isSubmitting || (hasSubmitted && hasErrors)}
                    width="large"
                    className="mx-auto block"
                    data-testid="login-submit"
                  >
                    <FormattedMessage
                      id="HC1HLX"
                      defaultMessage="Sign In"
                      description="Sign in button"
                    />
                  </Button>
                  {/* TODO: add guest registration for oauth */}
                  {!isOauth && !isTreasurer && (
                    <Link href={getDonateFlowUrl('/guest', orgId)}>
                      <Button
                        type="button"
                        variant="link"
                        width="large"
                        className="mx-auto block"
                        icon="rightArrow"
                      >
                        <FormattedMessage
                          id="1jFOG2"
                          defaultMessage="Donate as Guest"
                          description="Donate as Guest button"
                        />
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <ForgotPassword
        isOpen={forgotPassword}
        email={values.username}
        close={() => setForgotPassword(false)}
      />
    </>
  );
};
export default LoginFormStandalone;
